.bg-content-new {
    background-color: #F8F8F8 !important;
}

.title-content {
    font-size: 20px;
    color: #395B50;
    font-weight: bold;
}

.input-search {
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
}

.input-search-with-dropdown {
    border-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
}

.input-search-dropdown {
    position: absolute;
    background: white;
    overflow: auto;
    z-index: 1;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
    width: 100%;
}

.select-custom {
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 50px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
}

.form-border-green {
    border: 1px solid #00AF54 !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}

.title-sidebar {
    font-size: 18px;
    color: #395B50;
    font-weight: bold;
}

.subtitle-sidebar {
    font-size: 14px;
    color: #395B50;
    font-weight: bold;
}

.img-star-icon {
    width: 18px !important;
}

.font-in-box {
    font-size: 13px !important;
    color: white !important;
    font-weight: 400;
}

.font-in-box-market {
    font-size: 13px !important;
    color: white !important;
    font-weight: 500;
}

.font-in-box-sub-market {
    font-size: 13px !important;
    color: white !important;
}

.box-img-card {
    background: linear-gradient(to bottom, transparent 0%, black 100%) !important;
    height: 40% !important;
    place-content: end !important;
    font-weight: 300;
}

.box-img-card-70 {
    background: linear-gradient(to bottom, transparent 0%, black 100%) !important;
    height: 70% !important;
    place-content: end !important;
    font-weight: 300;
}

.box-img-card-cursor {
    height: 80% !important;
    place-content: end !important;
}

.box-img-card-top {
    background: linear-gradient(to top, transparent 0%, black 100%) !important;
    height: 40% !important;
    place-content: top !important;
    font-weight: 300;
}

.container {
    max-width: 100% !important;
}

.tab-active-new {
    color: #00AF54 !important;
    border-bottom-color: #00AF54 !important;
}

.tab-not-active-new {
    color: #787676 !important;
    border-bottom-color: #787676 !important;
}

.btn-border-green {
    text-align: center;
    cursor: pointer;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: #00AF54 !important;
    border: 1px solid #00AF54 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.btn-border-gray {
    text-align: center;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: #787676 !important;
    border: 1px solid #787676 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background-color: #cccccc;
}

.btn-border-green:hover {
    color: white !important;
    background-color: #00AF54 !important;
}

.btn-border-red {
    text-align: center;
    cursor: pointer;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: #FF2C2C !important;
    border: 1px solid #FF2C2C !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.btn-border-red:hover {
    color: white !important;
    background-color: #FF2C2C !important;
}

.btn-full-green {
    text-align: center;
    cursor: pointer;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: white !important;
    border: 1px solid #00AF54 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background-color: #00AF54;
}

.btn-full-green:hover {
    color: #00AF54 !important;
    background-color: white !important;
    border: 1px solid #00AF54 !important;
}

.border-green {
    border: 1px solid #00AF54 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 8px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.bg-gray {
    background-color: #c0c2c4 !important;
    color: #00AF54 !important;
}

.btn-line-2 {
    font-size: 14px !important;
    min-height: 75px !important;
}

.text-green {
    color: #00AF54 !important;
    font-weight: 500 !important;
}

.text-dark-green {
    color: #0E5934 !important;
    font-weight: 500 !important;
}

.text-red {
    color: red !important;
    font-weight: 500 !important;
}

.text-gray-sm {
    color: gray !important;
    font-size: 11px !important;
}

.text-gray {
    color: gray !important;
    font-weight: 500 !important;
}

.text-dark-green-sm {
    color: #0E5934 !important;
    font-size: 12px !important;
}

.fs-10-black {
    color: black !important;
    font-size: 10px !important;
}

.modal-large-custom>.modal-dialog {
    max-width: 500px !important;
    height: 600px;
    margin-top: 80px !important;
    @media (min-width: 300px) {
        max-width: 500px !important;
    }
    @media (min-width: 900px) {
        max-width: 850px !important;
    }

    @media (min-width: 1200px) {
        max-width: 1100px !important;
    }
}

.modal-confirmqty>.modal-dialog {
    max-width: 900px !important;
    height: 600px;
    margin-top: 60px !important;
    @media (min-width: 300px) {
        max-width: 500px !important;
    }
    @media (min-width: 900px) {
        max-width: 850px !important;
    }

    @media (min-width: 1200px) {
        max-width: 1100px !important;
    }
}

.modal-confirmation>.modal-dialog {
    max-width: 550px !important;
    height: 400px;
    margin-top: 80px !important;
}

.input-group-custom {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
}

.input-individual-custom {
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
}

.title-section {
    font-size: 16px;
    color: #395B50;
    font-weight: bold;
}

.btn-border-red {
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: red !important;
    border: 1px solid red !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.btn-border-red:hover {
    color: white !important;
    background-color: red !important;
}

.change-icon-color-green {
    color: #395B50;
    font-size: 40px;
    font-weight: bold;
}

.text-icon-color-green {
    color: #395B50;
    font-size: 16px;
    font-weight: bold;
}

.bg-content-new-white {
    background-color: white !important;
}

.title-crops-17-500 {
    font-size: 17px;
    color: #395B50;
    font-weight: 500;
}

.title-grade-15-300 {
    font-size: 17px;
    color: #395B50;
    font-weight: 400;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    min-height: 400px;
}

.black-bg-advance-booking-detail {
    background-color: #1F2F16 !important;
    color: white;
    border-radius: 8px 0px 0px 8px !important;

    @media (max-width: 500px) {
        width: 10.5rem;
    }
}

.gray-bg-advance-booking-detail {
    background-color: #82C784 !important;
    color: white;
    border-radius: 0px 8px 8px 0px !important;

    @media (max-width: 500px) {
        width: 10.5rem;
    }
}

.status-advance-booking-detail {
    @media (max-width: 500px) {
        margin-right: 1rem;
    }
}

.status-advance-booking-detail2 {
    @media (max-width: 500px) {
        margin-left: 1rem;
    }
}

.black-bg-advance {
    background-color: #353535 !important;
    color: white;
}

.gray-bg-advance {
    background-color: #787676 !important;
    color: white;
}

@media (min-width: 300px) {
    .margin-seidebar {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .input-search-width {
        width: 100% !important;
    }
}

@media (min-width: 500px) {
    .margin-seidebar {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .input-search-width {
        width: 100% !important;
    }
}

@media (min-width: 990px) {
    .margin-seidebar {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .input-search-width {
        width: 50% !important;
    }
}

.card-rounded-custom {
    border-radius: 8px !important;
}

.btn-red {
    border-radius: 8px !important;
    background-color: red !important;
    color: white !important;
}

.btn-red:hover {
    border-radius: 8px !important;
    background-color: red !important;
    color: white !important;
}

.btn-green {
    border-radius: 8px !important;
    background-color: #00AF54 !important;
    color: white !important;
}

.btn-green:hover {
    border-radius: 8px !important;
    background-color: #00AF54 !important;
    color: white !important;
}

.text-black {
    color: black !important;
}

.input-group-plot {
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-right: none !important;
    border-left: none !important;
}

.input-group-plot-quantity {
    background: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-right: none !important;
    border-left: none !important;
}


.tracking-detail {
    padding: 3rem 0;
}

#tracking {
    margin-bottom: 1rem;
}

[className*="tracking-status-"] p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

[className*="tracking-status-"] {
    padding: 1.6rem 0;
}

.tracking-list {
    border: 1px solid #e5e5e5;
}

.tracking-item {
    border-left: 4px solid #00ba0d;
    position: relative;
    padding: 2rem 1.5rem 0.5rem 2.5rem;
    font-size: 0.9rem;
    margin-left: 3rem;
    min-height: 5rem;
}

.tracking-item:last-child {
    padding-bottom: 4rem;
}

.tracking-item .tracking-date {
    margin-bottom: 0.5rem;
}

.tracking-item .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
}

.tracking-item .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
}

.tracking-item .tracking-content span {
    display: block;
    color: #767676;
    font-size: 13px;
}

.tracking-item .tracking-icon {
    position: absolute;
    left: -0.7rem;
    width: 1.1rem;
    height: 1.1rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    background-color: #fff;
    color: #fff;
}

.tracking-item-pending {
    border-left: 4px solid #d6d6d6;
    position: relative;
    padding: 2rem 1.5rem 0.5rem 2.5rem;
    font-size: 0.9rem;
    margin-left: 3rem;
    min-height: 5rem;
}

.tracking-item-pending:last-child {
    padding-bottom: 4rem;
}

.tracking-item-pending .tracking-date {
    margin-bottom: 0.5rem;
}

.tracking-item-pending .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
}

.tracking-item-pending .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
}

.tracking-item-pending .tracking-content span {
    display: block;
    color: #767676;
    font-size: 13px;
}

.tracking-item-pending .tracking-icon {
    line-height: 2.6rem;
    position: absolute;
    left: -0.7rem;
    width: 1.1rem;
    height: 1.1rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    color: #d6d6d6;
}

.tracking-item-pending .tracking-content {
    font-weight: 600;
    font-size: 17px;
}

.tracking-item .tracking-icon.status-current {
    width: 1.9rem;
    height: 1.9rem;
    left: -1.1rem;
}

.tracking-item .tracking-icon.status-intransit {
    color: #00ba0d;
    font-size: 0.6rem;
}

.tracking-item .tracking-icon.status-current {
    color: #00ba0d;
    font-size: 0.6rem;
}

@media (min-width: 992px) {
    .tracking-item {
        margin-left: 10rem;
    }

    .tracking-item .tracking-date {
        position: absolute;
        left: -7rem;
        width: 7.5rem;
        text-align: right;
    }

    .tracking-item .tracking-date span {
        display: block;
    }

    .tracking-item .tracking-content {
        padding: 0;
        background-color: transparent;
    }

    .tracking-item-pending {
        margin-left: 10rem;
    }

    .tracking-item-pending .tracking-date {
        position: absolute;
        left: -7rem;
        width: 7.5rem;
        text-align: right;
    }

    .tracking-item-pending .tracking-date span {
        display: block;
    }

    .tracking-item-pending .tracking-content {
        padding: 0;
        background-color: transparent;
    }
}

.tracking-item .tracking-content {
    font-weight: 600;
    font-size: 17px;
}

.blinker {
    border: 7px solid #e9f8ea;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    50% {
        border-color: #fff;
    }
}

.bg-green {
    background-color: #00AF54 !important;
    color: white !important;
}