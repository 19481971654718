@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .borderedButton {
    @apply w-full bg-white hover:bg-main hover:text-white hover:border-main hover:ring-2 hover:ring-main text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow;
  }
  .borderedwhiteButton {
    @apply w-full bg-white hover:border-main hover:ring-2 hover:ring-main text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow;
  }
  .borderedDeleteButton {
    @apply w-full bg-white hover:bg-red-400 hover:text-white hover:border-red-400 hover:ring-2 hover:ring-red-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow;
  }
  .borderedActiveButton {
    @apply w-full bg-main  hover:bg-green-600 hover:text-white hover:border-main hover:ring-2 hover:ring-main text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow;
  }
  .borderedInactiveButton {
    @apply w-full bg-red-400 hover:bg-red-600 hover:text-white hover:border-red-400 hover:ring-2 hover:ring-red-400 text-buttonText border-[.09rem] border-buttonBorder px-4 py-1 relative flex justify-center items-center rounded-xl shadow;
  }
  .saveButton {
    @apply w-full bg-main text-white border-main hover:bg-green-600 hover:ring-2 hover:ring-green-600 px-4 py-2 relative flex justify-center items-center rounded-xl shadow;
  }
  .indentedText {
    @apply w-full bg-white px-4 py-2 border-none rounded-full shadow-inner-xl focus:ring-green-500 focus:ring-2;
  }
  .btn-greyed {
    background-color: #cbd5e1;
  }
  .unborderedButton {
    @apply w-full bg-white hover:bg-main hover:text-white hover:border-main hover:ring-2 hover:ring-main text-buttonText border-buttonBorder px-4 py-2 relative flex justify-start items-center rounded-sm cursor-pointer ;
  }
}

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full px-4 py-2 border-none rounded-full shadow-inner-xl focus:ring-green-500 focus:ring-2;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply rounded border-gray-200 text-green-500 focus:ring-green-500;
  }
  [type="button"],
  [type="submit"] {
    @apply w-full bg-white text-buttonText px-4 py-2 relative flex justify-center items-center rounded-lg shadow;
  }
  [type="file"] {
    @apply file:bg-white file:border-none bg-white rounded-lg px-3 py-2 shadow;
  }
}
